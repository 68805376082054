// https://www.aleksandrhovhannisyan.com/blog/62-5-percent-font-size-trick/#setting-the-root-font-size-to-625percent
html {
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
  background: var(--bg-primary);
  overflow: hidden;
  position: fixed; // prevent scrolling
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  color: var(--font-primary);
  background: black;
}

#new-window-container {
  height: 100%;
  overflow: auto;
  background: white;
}

#root {
  overflow: hidden;
  background: var(--bg-primary);
  height: 100%;
  width: 100%;
}

// Safari ignores this for some reason, but that's ok. Other browsers will honor it.
*:focus,
*:active {
  outline-color: var(--blue-1);
}

* {
  box-sizing: border-box;
}

legend {
  max-width: fit-content;
}

input,
select {
  -webkit-appearance: none; // disable border-radius on iOS
}
