.urlForm {
  height: unset;
}

.addRow {
  display: flex;
  align-items: flex-end;
  & label {
    margin-right: 1rem;
  }
}

.addRowButton {
  background: var(--blue-1);
  margin-bottom: 0.8rem;
  width: 5rem;
  min-width: 5rem;
  height: 5rem;
  min-height: 5rem;
  border-radius: 50%;

  & svg path {
    fill: #fff;
  }
}

.toggleButton {
  path {
    fill: var(--font-primary);
  }
}

.feedList {
  position: relative;
  font-size: 1.4rem;

  p {
    margin: 0;
    font-weight: bold;
    border-bottom: 1px solid var(--nav-border);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    padding: var(--padding-default) 0;

    li {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr auto auto;
      overflow: hidden;

      button {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
