$background: transparent;
$scrollColor: var(--grey-4);

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $scrollColor $background;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: $background;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollColor;
  border-radius: 4px;
  // border: 3px solid $background;
}
