@import '../../scss-utils/';

.wrapper {
  height: var(--nav-width);
  z-index: 1000;
  position: relative;
}

.moreButton {
  height: var(--nav-width);
  width: var(--nav-width);
  padding: 0.5rem;

  svg {
    width: 100%;
    height: 100%;
    path {
      fill: var(--font-primary);
    }
  }
}

.contextMenu {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 50%;
  top: 50%;
  border: 1px solid var(--nav-border);
  box-shadow: var(--box-shadow-default);
  border-radius: var(--radius-default);
  overflow: hidden;
  z-index: 10000;
  min-width: 200px;
  padding: var(--radius-default) 0;
  background: var(--nav-bg);
}
