.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: var(--nav-width);
  width: var(--nav-width);
}

.icon {
  height: calc(100% - 2.2rem);
  width: calc(100% - 2.2rem);
  fill: var(--nav-link-inactive);
}

.label {
  font-size: 1rem;
  color: var(--nav-link-inactive);
}

.active {
  svg path {
    fill: var(--nav-link-active);
  }
  span {
    color: var(--nav-link-active);
  }
}

.regularLink span {
  color: var(--font-primary);
}
