.linkButton {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  color: var(--blue-1);
  cursor: pointer;

  & > svg path {
    fill: var(--font-primary);
  }
}
