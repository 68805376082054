@import '../../scss-utils/';

.search {
  height: var(--nav-width);
  width: 100%;
  border-bottom: 1px solid var(--nav-border);
  box-shadow: var(--box-shadow-default);
  background: var(--bg-primary);
  display: flex;
  z-index: 10;
  padding-right: env(safe-area-inset-right);

  input {
    width: 100%;
    height: var(--nav-width);
    font-size: 2.4rem;
    padding: 0.5rem 1rem;
    color: var(--font-primary);
    background: transparent;
    border: none;

    @media (min-width: $tablet-breakpoint) {
      font-size: 3.2rem;
    }
  }

  button {
    min-width: var(--nav-width);

    svg path {
      fill: var(--font-primary);
    }
  }
}
