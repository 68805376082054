@import '../../scss-utils/';

.removeableItemButton {
  margin-left: auto;
  margin-right: 0;
  height: auto;
  border: 2px solid var(--red-1);
  border-radius: var(--radius-default);
  transition: background 0.4s;
  width: var(--nav-width);
  max-width: var(--nav-width);
  min-width: var(--nav-width);
  & path {
    fill: var(--red-1);
  }

  &:hover,
  &:active {
    background: var(--red-1);
    & path {
      fill: $white-1;
    }
  }

  @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--nav-width);
    height: var(--nav-width);
  }
}
