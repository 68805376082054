@import '../scss-utils/';

.layout {
  height: 100%;
  width: 100%;
  position: relative;
  background: var(--bg-primary);
  transition: 0.4s filter;
}

.blurred {
  filter: blur(3px);
}

.loggedIn {
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: $tablet-breakpoint) {
    flex-direction: row;
  }
}

.body {
  height: calc(100% - var(--nav-height-with-safe-area));
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-breakpoint) {
    height: 100%;
    width: calc(100% - var(--nav-width));
  }
}

.progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  border-radius: 0;
  height: 0.4rem;
  pointer-events: none;

  & > div {
    background: var(--blue-1);
    transition: transform 0.4s;
    height: 100%;
  }
}
