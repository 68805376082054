@import '../scss-utils/';

// CSS variables
:root {
  --transparent-bg: rgba(255, 255, 255, 0.65);
  --bg-primary: #{$white-1};
  --bg-secondary: #{$white-2};
  --nav-bg: #{$white-2};
  --nav-border: #{$grey-2};
  --nav-link-active: #{$black-1};
  --nav-link-inactive: #{$grey-5};
  --font-primary: #{$black-2};
  --font-disabled: #{$grey-4};
  --blue-1: #{$blue-1};
  --blue-2: #{$blue-2};
  --red-1: #{$red-1};
  --green-1: #{$green-1};
  --green-2: #{$green-2};
  --white-1: #{$white-1};
  --white-2: #{$white-2};
  --grey-1: #{$grey-1};
  --grey-2: #{$grey-2};
  --grey-3: #{$grey-3};
  --grey-4: #{$grey-4};
  --grey-5: #{$grey-5};
  --border-default: unset;
  --box-shadow-default: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  --radius-default: #{$radius};
  --radius-small: #{$radiusSmall};
  --nav-width: #{$navWidth};
  --nav-width-min: #{$navWidth};
  --nav-height-with-safe-area: calc(#{$navWidth} + env(safe-area-inset-bottom));
  --nav-width-with-safe-area: calc(#{$navWidth} + env(safe-area-inset-left));
  --padding-default: #{$paddingSmall};
  --padding-default-min: #{$paddingSmall};
  --phone-breakpoint: #{$phone-breakpoint};
  --tablet-breakpoint: #{$tablet-breakpoint};
  --desktop-breakpoint: #{$desktop-breakpoint};
  --safe-max-width: calc(
    100% - (env(safe-area-inset-left) + env(safe-area-inset-right))
  );
  --safe-max-height: calc(
    100% - (env(safe-area-inset-top) + env(safe-area-inset-bottom))
  );

  // Dark mode
  @media (prefers-color-scheme: dark) {
    --transparent-bg: rgba(0, 0, 0, 0.65);
    --bg-primary: #{$black-2};
    --bg-secondary: #{$black-4};
    --nav-bg: #{$black-3};
    --nav-border: #{$grey-3};
    --nav-link-active: #{$white-1};
    --nav-link-inactive: #{$grey-4};
    --font-primary: #{$grey-1};
    --font-disabled: #{$grey-3};
    --blue-1: #{$blue-2};
    --blue-2: #{$blue-1};
    --green-1: #{$green-2};
    --green-2: #{$green-1};
    --white-1: #{$white-2};
    --white-2: #{$white-1};
    // --grey-1: #{$grey-1};
    // --grey-2: #{$grey-2};
    // --grey-3: #{$grey-3};
    // --grey-4: #{$grey-4};
    // --grey-5: #{$grey-5};
    --border-default: #{$grey-1};
    --box-shadow-default: unset;
    // --radius-default: #{$radius};
  }

  @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
    --nav-width: #{$navWidthWide};
    --padding-default: #{$paddingLarge};
  }
}

@import './base';
@import './link';
@import './scrollbar';
