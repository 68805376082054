.table {
  border-spacing: 0;
  border: 1px solid black;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;

  .thead {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .tbody {
    overflow-y: scroll;
    overflow-x: hidden;
    // height: 250px;
  }

  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
    border-bottom: 1px solid black;
  }

  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    border-right: 1px solid black;

    position: relative;

    :last-child {
      border-right: 0;
    }

    .resizer {
      right: 0;
      background: blue;
      width: 10px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
      touch-action: none;

      &.isResizing {
        background: red;
      }
    }
  }
}
