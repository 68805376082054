@import '../../scss-utils/';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  padding: var(--padding-default);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
    box-shadow: var(--box-shadow-default);
    border: 1px solid var(--border-default);
    border-radius: var(--radius-default);
    margin-top: 10vw;
    width: 34rem;
    height: unset;
  }
}

.logo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.6rem;
  > img {
    width: 6.4rem;
    height: 6.4rem;
  }

  @media (min-width: $tablet-breakpoint) and (max-height: $phone-landscape-height) {
    position: absolute;
    left: var(--padding-default);
    top: var(--padding-default);
    margin: 0;
  }
}

.footer {
  width: 100%;
  margin-top: 1.6rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  @media (min-width: $tablet-breakpoint) and (max-height: $phone-landscape-height) {
    max-width: 32rem;
  }
}

.backLink {
  display: flex;
  align-items: center;

  svg path {
    fill: var(--font-primary);
  }
}
