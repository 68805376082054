$phone-breakpoint: 376px;
$tablet-breakpoint: 555px;
$desktop-breakpoint: 961px;
$phone-landscape-height: 415px;

$navWidth: 5rem;
$navWidthWide: 6rem;

$blue-1: #3789c9;
$blue-2: #377ba6;
$red-1: #f44417;
$green-1: #5d902b;
$green-2: #7ea142;
$white-1: #fff;
$white-2: #f2f2f2;
$grey-1: #f5f5f5;
$grey-2: #dedddd;
$grey-3: #b0afaf;
$grey-4: #908e8e;
$grey-5: #7e7c7c;
$black-1: #000;
$black-2: #242424;
$black-3: #333;
$black-4: #4d4d4d;
$radius: 0.8rem;
$radiusSmall: 0.5rem;
$paddingLarge: 3rem;
$paddingSmall: 1rem;
