@import '../../../../scss-utils/';

.userList {
  max-height: 160px;
  overflow: auto;
  padding-right: var(--padding-default);
  font-size: 1.4rem;

  @media (min-width: $tablet-breakpoint) {
    max-height: 240px;
    font-size: 1.6rem;
  }
}

.tip {
  font-size: 1.4rem;
  font-style: italic;
}
