@import '../../../scss-utils/';

.shareList {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: var(--padding-default) 0;
  padding: 0;
  gap: var(--padding-default);
}

.shareItem {
  & a {
    padding: var(--padding-default);
    display: flex;
    align-items: center;
    gap: var(--padding-default);
    border-radius: var(--radius-default);
    border: 1px solid var(--blue-1);
    transition: background 0.4s;
    &:hover {
      background: var(--blue-1);
      color: $white-1;

      svg path {
        fill: $white-1;
      }
    }

    svg path {
      fill: var(--blue-1);
    }
  }
}
