@import '../../scss-utils/';

.avatar {
  &--btn {
    cursor: pointer;
  }

  &--x-large {
    width: 16rem;
    height: 16rem;
    min-width: 16rem;
    min-height: 16rem;
    // font-size: 6.4rem;
  }

  &--large {
    width: 9rem;
    height: 9rem;
    min-width: 9rem;
    min-height: 9rem;
    // font-size: 3.6rem;
  }

  &--medium {
    width: 7rem;
    height: 7rem;
    min-width: 7rem;
    min-height: 7rem;
    // font-size: 2.8rem;
  }

  &--small {
    width: 5rem;
    height: 5rem;
    min-width: 5rem;
    min-height: 5rem;
    // font-size: 2rem;
  }

  &--x-small {
    width: 3.5rem;
    height: 3.5rem;
    min-width: 3.5rem;
    min-height: 3.5rem;
    // font-size: 1.4rem;
  }

  &--no-value {
    background: var(--bg-secondary);
  }
}
