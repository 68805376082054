@import '../../scss-utils/';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.body,
.footer {
  padding: var(--padding-default);
}

.body {
  overflow-y: auto;
  @extend %gutter;
}

.textFieldLabel {
  display: flex;
  flex-direction: column;
  margin: 0.8rem 0;
  width: 100%;
}

.textFieldLabel,
.textFieldInput,
.fieldset,
.checkboxLabel,
.dropdownWrapper,
.singleFile {
  width: 100%;
  @media (min-width: $tablet-breakpoint) {
    max-width: 32rem;
  }

  span {
    font-size: 1.4rem;
  }
}

.textFieldInput,
.dropdown {
  height: 5rem;
  min-height: 5rem;
  padding: 1.2rem 1.6rem;
  border-radius: var(--radius-default);
  background: var(--bg-secondary);
  border: 1px solid var(--grey-3);
  color: var(--font-primary);
  &:active,
  &:focus {
    background: none;
  }
}

.footer {
  margin-top: auto;
  margin-bottom: 0;
  padding-top: var(--padding-default);
  display: flex;
  justify-content: flex-end;
  gap: var(--padding-default);
  position: sticky;
  bottom: 0;
  background: var(--bg-primary);
  border-top: 1px solid var(--nav-border);
  box-shadow: var(--box-shadow-default);
}

.fieldset {
  margin: 0;
  border: 1px solid var(--nav-border);
  border-radius: var(--radius-default);
  display: flex;
  flex-direction: column;

  legend {
    font-weight: bold;
    font-size: 1.4rem;
  }
}

.checkboxLabel {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0.8rem 0;

  span,
  input {
    margin-left: 0;
  }

  span {
    margin-left: 1rem;
    margin-right: auto;
  }

  div {
    position: relative;
  }
}

.dropdown {
  width: 100%;
}

.dropdownWrapper {
  position: relative;

  svg {
    position: absolute;
    right: 0;
    top: calc(50%);
    transform: translateY(-50%);
    pointer-events: none;
    path {
      fill: var(--font-primary);
    }
  }
}

.hideLabel {
  @extend %visually-hidden;
}

.fullWidth {
  max-width: 100%;
  input,
  textarea {
    max-width: 100%;
  }
}

.textArea {
  min-height: 200px;
}

.singleFile {
  &::-webkit-file-upload-button {
    border: none;
    background: var(--bg-secondary);
    border: 1px solid var(--grey-3);
    border-radius: var(--radius-default);
    padding: 1.2rem 1.6rem;
    color: var(--font-primary);
    cursor: pointer;
    transition: background 0.4s;
  }

  &:hover::-webkit-file-upload-button {
    background: none;
  }

  &:active::-webkit-file-upload-button {
    background: var(--grey-3);
  }
}

.hidden {
  @extend %visually-hidden;
}

.checkboxWrapper {
  position: relative;
}

.checkbox {
  height: 2.4rem;
  width: 2.4rem;
  border-radius: var(--radius-default);
  background: var(--bg-secondary);
  border: 1px solid var(--grey-3);
  color: var(--font-primary);
  &:checked,
  &:indeterminate {
    background: var(--grey-4);
  }

  &:active,
  &:focus {
    &:checked,
    &:indeterminate {
      background: var(--blue-1);
    }
  }

  &:checked + svg {
    pointer-events: none;
    position: absolute;
    height: 1.8rem;
    width: 1.8rem;
    top: 0.3rem;
    left: 0.3rem;

    path {
      fill: $white-1;
    }
  }

  &:indeterminate {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 4px;
      top: calc(50% - 1px);
      width: calc(100% - 8px);
      height: 2px;
      background: $white-1;
    }
  }
}

.range {
  width: 100%;
  appearance: auto;
}
