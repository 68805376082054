@import '../../../scss-utils/';

.contextItem {
  button {
    background: var(--nav-bg);
    color: var(--font-primary);
    padding: var(--radius-default);
    font-size: 1.4rem;
    border: 0;
    width: 100%;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:disabled {
      color: var(--font-disabled);
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background: var(--blue-1);
      color: $white-1;

      svg path {
        fill: $white-1;
      }
    }

    svg {
      height: 1.6rem;
      width: 1.6rem;
      path {
        fill: var(--font-primary);
      }
    }
  }
}
