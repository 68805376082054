.wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
}

.first,
.second {
  white-space: nowrap;
  overflow: hidden;
}

.first {
  text-overflow: ellipsis;
}

.second {
  flex: 1 0 auto;
}
