@import '../../scss-utils/';

.iconButton {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  width: var(--nav-width);
  height: var(--nav-width);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.badge {
  position: absolute;
  top: 12%;
  right: 12%;
  color: $white-1;
  font-size: 1rem;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow-default);
  border: 1px solid var(--nav-border);
}

.red {
  background: var(--red-1);
}

.blue {
  background: var(--blue-1);
}

.white {
  background: $white-1;
  color: $black-1;
}
