@import '../../scss-utils/';

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  text-transform: uppercase;
  border-radius: 50%;
  color: #fff;
  border: none;
  position: relative;
}

.icon {
  path {
    fill: $white-1;
  }
}

.definitions {
  @extend %visually-hidden;
}
