.address {
  display: flex;
  flex-direction: column;
  margin: 0;
  dt {
    font-weight: bold;
  }
  dd {
    margin: 0;
  }
}
