@import '../../scss-utils/';

.article {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-breakpoint) {
    padding-bottom: env(safe-area-inset-bottom);
  }

  img {
    max-width: 100%;
    max-height: 40vh;
    min-height: 24rem;
    width: auto;
    height: auto;
    margin: 0 auto;
  }

  blockquote {
    font-style: italic;
    margin: var(--padding-default) 0;
    border-left: 0.3rem solid var(--blue-1);
    padding: var(--padding-default);
    background: var(--nav-bg);
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--nav-border);
    margin: 1em 0;
    padding: 0;
  }

  figure {
    margin: var(--padding-default);
    text-align: center;
  }

  ul,
  ol {
    margin: 0 var(--padding-default);
    padding: 0 var(--padding-default);
  }
}

.articleTitle {
  font-weight: 500;
  font-size: 2.4rem;
  margin: 0;
}

.snippetContent {
  white-space: pre-line;
}

.categories {
  display: flex;
  padding: var(--padding-default) 0;
  gap: 0.5rem;
  flex-wrap: wrap;

  & > * {
    padding: 0.2rem 0.5rem;
    background: var(--nav-bg);
    border-radius: var(--radius-default);
    font-size: 1.2rem;
  }
}

.byline {
  padding: var(--padding-default) 0;
}

.footerLink {
  a {
    display: flex;
    align-items: center;
    & > *:first-child {
      margin-right: 0.5rem;
    }
  }
  svg {
    path {
      fill: var(--blue-1);
    }
  }
}

.preview {
  display: block;

  // https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
  min-width: 0;

  .container {
    border: 1px solid var(--nav-border);
    box-shadow: var(--box-shadow-default);
    border-radius: var(--radius-default);
    overflow: hidden;
    color: var(--font-primary);
    position: relative;
  }

  .articleTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 2rem;
  }

  .featuredImage {
    width: 100%;
    height: 20rem;

    @media (min-width: $tablet-breakpoint) {
      height: 24rem;
    }

    @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
      height: 48rem;
    }
  }

  .text {
    padding: var(--padding-default);
    bottom: 0;
    background: var(--transparent-bg);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--padding-default);
  }

  p {
    margin: 0;
    font-size: 1.2rem;
  }

  .contentSnippet {
    margin-top: var(--padding-default);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.lastArticle {
  padding-bottom: calc(var(--padding-default) + env(safe-area-inset-bottom));
}

.logo {
  width: var(--nav-width);
  height: var(--nav-width);
}
