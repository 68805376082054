@import '../../../../scss-utils/';

.similarIssues {
  p {
    margin: 0;
    font-size: 1.4rem;
  }
  ul {
    margin: 0;
    list-style: none;
    padding: 0;
  }
  li {
    padding: 0.5rem 0;
    align-items: center;
    width: 100%;

    details {
      width: 100%;
      summary {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        & > * {
          display: inline;
        }
      }
    }
  }
}

.issueBody {
  font-size: 1.2rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.labels {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.label {
  color: $white-1;
  border-radius: var(--radius-default);
  padding: 0.3rem 0.5rem;
  font-style: normal;
}

.issueTitle {
  font-weight: bold;
  font-size: 1.4rem;
}
