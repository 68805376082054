@import '../../scss-utils/';

.directory {
  font-size: 1.2rem;
  background: white;
}

.printButton {
  border-radius: 50%;
  background: var(--blue-1);
  position: fixed;
  top: var(--padding-default);
  right: var(--padding-default);
  box-shadow: var(--box-shadow-default);

  svg path {
    fill: $white-1;
  }

  @media print {
    display: none;
  }
}

.memberGroups {
  & .members {
    border-top: 1px dashed $grey-3;
  }
  & .members:first-child {
    border-top: none;
  }
}

.surname {
  min-height: $navWidth;
  padding: $paddingSmall;
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media print {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 9999px solid $grey-1;
      z-index: -1;
    }
  }
}

.address {
  font-size: 1.2rem;
  font-weight: normal;
}

.members {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $paddingSmall;
  padding: $paddingSmall;
}

.user {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: $paddingSmall;
}

.fullName {
  font-size: 1.4rem;
  font-weight: bold;
}
