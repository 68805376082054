@import '../../scss-utils/';

.main-nav {
  border-top: 1px solid var(--nav-border);
  box-shadow: var(--box-shadow-default);
  background: var(--nav-bg);
  height: var(--nav-height-with-safe-area);
  flex: 1 0 var(--nav-height-with-safe-area);
  position: sticky;
  z-index: 100;

  &__logo {
    display: none;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;

    li {
      height: var(--nav-width);
      width: var(--nav-width);
    }
  }

  @media (min-width: $tablet-breakpoint) {
    border-top: unset;
    border-right: 1px solid var(--nav-border);
    height: unset;
    width: var(--nav-width-with-safe-area);
    flex: 1 0 var(--nav-width-with-safe-area);
    padding-left: env(safe-area-inset-left);

    &__logo {
      display: block;
      width: var(--nav-width);
      height: var(--nav-width);
    }

    &__list {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
