@import '../../scss-utils/';

// clear the default <dialog> styles
.self {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: var(--safe-max-width);
  height: var(--safe-max-height);
  max-width: var(--safe-max-width);
  max-height: var(--safe-max-height);
  color: var(--font-primary);
  display: flex;
  align-items: center;
  justify-content: center;

  &::backdrop {
    background: rgba(0, 0, 0, 0.25);
  }

  // Remove fieldset border
  fieldset {
    border: none;
    padding: 0;
  }
}

.fixed {
  position: fixed;
  top: env(safe-area-inset-top);
  right: env(safe-area-inset-right);
  bottom: env(safe-area-inset-bottom);
  left: env(safe-area-inset-left);
}

.wrapper {
  padding: 3.6rem;
  position: relative;
  max-height: 100%;
  max-width: 102.4rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;

  & > div {
    border: 1px solid var(--nav-border);
    box-shadow: var(--box-shadow-default);
    width: 100%;
    overflow: hidden;
    border-radius: var(--radius-default);
    border: 1px solid var(--nav-border);
    display: flex;
    flex-direction: column;
  }
}

.closeBtn {
  position: absolute;
  top: 1.8rem;
  right: 1.8rem;
  width: 3.6rem;
  height: 3.6rem;
  background-image: linear-gradient(var(--bg-primary), var(--bg-primary));
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: center;

  & svg {
    fill: var(--font-primary);
  }
}

.header {
  padding: var(--padding-default);
  background: var(--bg-primary);
  padding-bottom: var(--padding-default);
  // border-bottom: 1px solid var(--nav-border);

  h2 {
    margin: 0;
    font-size: 2.4rem;
  }
}

.body {
  padding: var(--padding-default);
  padding-top: 0;
  padding-bottom: 0;
  background: var(--bg-primary);
  overflow-y: auto;
}

.footer {
  padding: var(--padding-default);
  background: var(--bg-primary);
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-right: var(--padding-default);
  }

  & > *:last-child {
    margin-right: 0;
  }
}
