.error,
.delete-phone,
.confirm-delete-church,
.add-phone,
.edit-phone,
.delete-user,
.edit-birthday,
.delete-birthday,
.add-email,
.change-email,
.confirm-new-email,
.delete-address,
.change-role,
.change-password,
.add-profile-picture,
.delete-profile-picture,
.share-directory,
.delete-listing,
.market-search {
  max-width: 42rem;
}

.create-family,
.create-user,
.edit-address,
.create-address,
.hide-user,
.change-name,
.change-family-name,
.change-head-of-household {
  max-width: 45rem;
}

.change-blog-feeds,
.directory-search {
  max-width: 68rem;
}

.feedback,
.create-listing,
.edit-listing {
  max-width: 76.8rem;
}

.send-message {
  max-width: 102.4rem;
}

.create-church,
.edit-church {
  max-width: 128rem;
  form main {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(28rem - var(--padding-default) * 2), 1fr)
    );
    gap: var(--padding-default);

    fieldset {
      height: fit-content;
    }
  }
}

.edit-church {
  max-width: 96rem;
}

.delete-family,
.move-user {
  max-width: 72rem;
}
