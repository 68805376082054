@import '../../scss-utils/';

.marketList {
  display: flex;
  flex-direction: column;
  gap: var(--padding-default);
  padding: var(--padding-default);
  padding-right: calc(var(--padding-default) + env(safe-area-inset-right));

  @extend %gutter;
}

.listingPreview {
  border: 2px solid var(--blue-1);
  border-radius: var(--radius-default);
  padding: var(--padding-default);
  display: flex;
  flex-direction: column;
  gap: var(--padding-default);
  position: relative;
}

.listingTitle {
  display: flex;
  h3 {
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.contextMenu {
  position: absolute;
  top: 0;
  right: 0;
}

.listingDate {
  font-size: 1.2rem;
}

.listingBody {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.listingAuthor {
  display: flex;
  align-items: center;
  gap: 1rem;

  span {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 0.5rem;
    font-size: 1.2rem;
    background: var(--blue-1);
    padding: 0.2rem 0.5rem;
    border-radius: var(--radius-default);
    align-self: flex-end;
    color: $white-1;

    svg {
      width: 2rem;
    }

    path {
      fill: $white-1;
    }
  }
}
