.button {
  border-radius: var(--radius-default);
  padding: 1.2rem 1.6rem;
  min-width: 12rem;
  border: none;
  cursor: pointer;
  transition: background 0.4s;
  background: var(--grey-2);

  &:disabled {
    cursor: not-allowed;
    background: var(--grey-5);
  }

  &:not([disabled]):hover {
    background: var(--grey-3);
  }
}

.primary {
  background: var(--blue-1);
  color: var(--white-1);

  &:not([disabled]):hover {
    background: var(--blue-2);
  }
}

.secondary {
  background: var(--green-1);
  color: var(--white-1);

  &:not([disabled]):hover {
    background: var(--green-2);
  }
}
