%visually-hidden {
  min-height: unset !important;
  display: unset !important;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

%gutter {
  max-width: 128rem;
  width: 100%;
  margin: 0 auto;
}

%link {
  color: var(--blue-2);
  text-decoration: none;
  transition: color 0.4s;
  &:hover {
    color: var(--blue-1);
  }
}
