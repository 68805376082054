@import '../../../scss-utils/';

.topRow {
  height: var(--nav-width);
  z-index: 5;
  background: var(--bg-primary);
  width: 100%;
  grid-column: 1 / -1;
  border-bottom: 1px solid var(--nav-border);
  box-shadow: var(--box-shadow-default);
  padding-right: env(safe-area-inset-right);

  & > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @extend %gutter;
  }
}

.detailsHeader {
  position: relative;
  z-index: 1;
  padding: var(--padding-default) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: auto;
  overflow: hidden;
  border-bottom: 1px solid var(--nav-border);

  @media (min-width: $tablet-breakpoint) {
    border-right: 1px solid var(--nav-border);
    border-bottom: unset;
  }

  @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
    border-bottom: 1px solid var(--nav-border);
    border-right: unset;
  }

  h2 {
    font-size: 2rem;
    margin: 0;
  }
}

.blurBg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: opacity(0.5) blur(10px);
}
