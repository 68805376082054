@import '../../scss-utils/';

.editControls button,
.editControls a,
.doneButton {
  color: var(--font-primary);
  background: var(--blue-1);
  transition: background 0.4s;

  &:hover {
    background: var(--blue-2);
  }

  svg path {
    fill: $white-1;
  }
}

.editControls {
  position: fixed;
  bottom: var(--nav-height-with-safe-area);
  right: 0;
  z-index: 100000;
  padding: var(--padding-default);
  align-self: center;
  display: flex;
  flex-direction: row;

  & > * {
    margin-left: var(--padding-default);
  }

  & > *:first-child {
    margin-left: 0;
  }

  @media (min-width: $tablet-breakpoint) {
    bottom: env(safe-area-inset-bottom);
    right: env(safe-area-inset-right);
  }

  button,
  a {
    border-radius: 50%;
    box-shadow: var(--box-shadow-default);
  }
}

.doneButton {
  position: fixed;
  bottom: var(--nav-height-with-safe-area);
  right: 0;
  width: 100%;
  color: $white-1;
  grid-column: 1 / -1;
  height: var(--nav-width);
  z-index: 5;

  @media (min-width: $tablet-breakpoint) {
    bottom: 0;
    height: var(--nav-height-with-safe-area);
    padding-bottom: env(safe-area-inset-bottom);
    width: calc(100% - var(--nav-width-with-safe-area));
  }
}
