@import '../../scss-utils/';

.page {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.pageBody {
  width: 100%;
  height: 100%;
  flex: 1;
  margin: 0 auto;
  position: relative;
  flex-direction: column;
}

.error {
  padding: var(--padding-default);
  @extend %gutter;
}

.header {
  @extend %visually-hidden;
}

.visibleHeader {
  height: var(--nav-width);
  width: 100%;
  border-bottom: 1px solid var(--nav-border);
  box-shadow: var(--box-shadow-default);
  background: var(--bg-primary);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    @extend %visually-hidden;
  }

  h1 {
    margin: 0;
    font-size: 2.4rem;

    @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
      font-size: 3.2rem;
    }
  }
}

.backLink {
  width: var(--nav-width);
  height: var(--nav-width);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg path {
    fill: var(--font-primary);
  }
}

.fallbackBody {
  padding: var(--padding-default);
  @extend %gutter;
}
