.environment-banner {
  position: fixed;
  z-index: 10000;
  bottom: 5px;
  left: -45px;
  background: red;
  color: #fff;
  text-align: center;
  width: 120px;
  height: 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
  font-weight: bold;

  &--stage {
    background: orange;
  }
}
