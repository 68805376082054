@import '../../scss-utils/';

.grid {
  display: flex;
  flex-direction: column;

  // workaround for gap support
  & > * {
    margin-bottom: var(--padding-default);
  }
  & > *:last-child {
    margin-bottom: 0;
  }

  padding: var(--padding-default);
  margin-bottom: var(--nav-width);
  @extend %gutter;

  @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
    display: grid;
    justify-content: space-around;
    grid-auto-rows: max-content;
    grid-template-columns: repeat(auto-fill, 240px);
    gap: var(--padding-default);

    // remove workaround for gap support
    & > * {
      margin-bottom: 0;
    }
  }
}

.gridNoItems {
  padding: 0;
}

.gridError {
  width: max-content;
}

.gridItem {
  padding-right: env(safe-area-inset-right);
  & > * {
    width: 100%;
    height: 100%;
  }
}

.stickyHeader {
  width: 100%;
  position: sticky;
  height: calc(var(--nav-width) - 1rem);
  z-index: 1;
  background: var(--nav-bg);
  border: 0;
  padding: 0;
  cursor: pointer;
  color: var(--font-primary);
  top: 0;
  bottom: 0;

  & > div {
    @extend %gutter;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: inherit;
    padding: 0 var(--padding-default);
    font-size: 1.4rem;
    font-weight: bold;

    svg path {
      fill: var(--font-primary);
    }
  }
}
