.breadcrumbs {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  height: var(--nav-width);

  // keep colors consistent in dark mode
  svg path {
    fill: var(--font-primary);
  }

  // hover color
  a {
    svg path {
      transition: fill 0.4s;
      fill: var(--blue-2);
    }

    &:hover svg path {
      fill: var(--blue-1);
    }
  }
}

.crumb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: var(--nav-width);
  width: var(--nav-width);
}

.slash {
  line-height: var(--nav-width);
}
