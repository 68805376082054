@import '../../../scss-utils/';

.itemContent {
  width: 100%;
}

.basicFlex {
  border: 2px solid transparent;
  width: 100%;
  flex: 0 1 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-right: var(--padding-default);

    @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
      margin-right: 0;
    }
  }

  & > *:last-child {
    margin-right: 0;
  }

  color: var(--font-primary);
  border-radius: var(--radius-default);
  padding: var(--padding-default);
  position: relative;
  text-align: left;

  @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
    flex-direction: column;
    text-align: center;
  }

  p {
    margin: 0;
    width: 100%;
    word-wrap: break-word;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
  }
}

.clickable {
  cursor: pointer;
  transition: border-color 0.4s, opacity 0.4s;
  &:hover {
    border-color: var(--blue-1);
    color: var(--font-primary);
  }
}

.clickableFlex {
  @extend .basicFlex;
  @extend .clickable;
}

.disabledLink {
  pointer-events: none;
  opacity: 0.5;
}

.removableItem {
  display: flex;

  & > * {
    margin-right: var(--padding-default);

    @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
      margin-right: 0;
    }
  }

  & > *:last-child {
    margin-right: 0;
  }

  position: relative;
  // content
  & > *:first-child {
    flex: 1 0 calc(100% - (var(--nav-width) + var(--padding-default)));
  }
}

.userOrFamilyItem {
  display: flex;
  padding: 0;

  & > a {
    padding: var(--padding-default);
    display: flex;
    align-items: center;
    gap: var(--padding-default);
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: auto;

    // override color
    color: var(--font-primary);
    &:focus,
    &:hover {
      color: var(--font-primary);
    }

    @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
      flex-direction: column;
      text-align: center;
      margin-right: unset;
      width: 100%;
    }
  }

  .quickContactButtons {
    display: none;
    @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
      display: flex;
    }
  }

  &:hover,
  &:focus {
    & .quickContactButtons {
      display: flex;
      opacity: 1;
    }
  }
}

.quickContactButtons {
  align-items: center;
  gap: var(--padding-default);
  opacity: 0;
  transition: 0.4s opacity;

  // padding if there are any items
  & > :first-child {
    margin-left: var(--padding-default);
  }
  & > :last-child {
    margin-right: var(--padding-default);
  }

  & > * {
    background: var(--blue-1);
    border-radius: 50%;

    @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
      width: var(--nav-width-min);
      height: var(--nav-width-min);
    }
    & path {
      fill: $white-1;
    }
  }

  @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
    margin-bottom: var(--padding-default);
    position: absolute;
    bottom: 0;
    gap: var(--padding-default-min);
  }
}
