$smallPadding: calc(var(--padding-default) / 2);

.familyList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, auto));
  gap: $smallPadding;
  list-style: none;
  padding: var(--padding-default) 0;
  margin: 0;

  li {
    & > * {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      & > * {
        margin-right: $smallPadding;
      }

      & > *:last-child {
        margin-right: 0;
      }
    }
  }
}

.isOptimistic {
  opacity: 0.5;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox {
  width: unset;
  margin-left: auto;
  margin-right: 0;
  margin-top: unset;
  margin-bottom: unset;
}
