@import '../../scss-utils/';

.body {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  height: 100%;
  overflow: hidden;

  @media (min-width: $tablet-breakpoint) {
    grid-template-rows: auto 1fr;
    grid-template-columns: 3fr 5fr;
  }

  @media (min-width: $tablet-breakpoint) and (min-height: $phone-landscape-height) {
    grid-template-rows: auto auto 1fr;
    grid-template-columns: unset;
  }
}

.backLink {
  position: absolute;
  top: 0;
  left: 0;
  padding: var(--padding-default);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  path {
    fill: var(--font-primary);
  }
}

.list {
  position: relative;
  overflow-y: auto;

  // https://stackoverflow.com/questions/67076468/why-scrollbar-is-behind-sticky-elements-in-ios-safari
  -webkit-transform: translateZ(0);
}

.listEditing {
  max-height: calc(100% - var(--nav-width));
}
